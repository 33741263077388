body {
  .client-panel {
    margin-top: 10px;
    background-color: #f7f7f7;
    min-height: calc(90vh - 3.5em);
    padding-bottom: 10px;
    top: 3.5em;
  }
  .breadcrumb {
    background-color: #f7f7f7;
    border-bottom: #f7f7f7;
  }
  .table-panel {
    background-color: white;
    height: 100%;
    margin: 0px 10px;
    .table-row {
      background-color: white;
      text-align: left;
    }
    .ReactTable * {
      box-sizing: border-box;
      text-align: left;
    }
    .ReactTable .rt-tbody .rt-td {
      padding: 10px;
      display: flex;
      align-items: center;
    }
    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
      padding: 10px;
      background-color: white;
      display: flex;
      align-items: center;
    }
  }

  .client-top {
    display: flex;
    .client-bread {
      display: flex;
      flex: 1;
    }
    .client-search {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 1em;
      .client-search-input {
        width: 50%;
      }
    }
  }

  .detail-panel {
    background-color: white;
    height: 100%;
    padding: 20px;
    margin: 10px;
    .detail-item {
      height: 360px;
      margin-bottom: 10px;
      .detail-card {
        height: 100%;
        .basic-panel {
          display: flex;
          .basic-left {
            flex: 1;
            font-size: 0.8em;
            .basic-image {
              text-align: center;
              margin-bottom: 15px;
              .image-style {
                width: 90px;
                height: 90px;
                border: 2px solid #f7f7f7;
                margin: 5px;
              }
            }
            .text-row {
              display: flex;
              margin: 8px 0px;
              letter-spacing: 3px;
              .text-key {
                flex: 1;
                display: flex;
                justify-content: flex-end;
              }
              .text-value2 {
                flex: 1;
                display: flex;
                font-weight: 500;
                white-space: nowrap;
                letter-spacing: normal;
              }
            }
          }
        }
      }
    }
  }

  .over-card {
    overflow: scroll;
  }

  .lv-panel {
    .lv-item {
      padding: 3px;
      float: left;
      width: 100%;
      display: flex;
      .lv-item-left {
        flex: 1;
      }
      .lv-item-middle {
        flex: 1;
      }
      .warning {
        color: red;
      }
      .lv-item-right {
        flex: 1;
        color: rgb(179, 179, 179);
        .action:hover {
          cursor: pointer;
        }
      }
      .active {
        color: blue
      }
      .inactive {
        color: darkgrey;
      }
    }
    .lv-main {
      clear: both;
    }
  }

  .detail-circle-panel {
    width: 24.5%;
    float: left;
    margin-bottom: 20px;
    .detail-circle {
      clear: both;
      width: 110px;
      height: 110px;
      border: solid 1px #3FA9F5;
      border-radius: 50%;
      font-size: 1.6em;
      color: #000;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .circle-top {
        height: 50%;
        display: flex;
        align-items: flex-end;
        font-size: 0.65em;
      }
      .circle-bottom {
        font-size: 0.5em;
        height: 50%;
      }
      .control-bottom {
        font-size: 0.6em;
        color: rgb(179, 179, 179);
      }
      .slider {
        width: 60%;
      }
    }
  }

  .time-top {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    .time-top-left {
      flex: 1;
      color: #000;
    }
    .time-top-right {
      flex: 4;
      display: flex;
      align-items: center;
      a:hover {
        cursor: pointer;
      }
      .link {
        color: #007f5f;
      }
    }
  }
  .time-content {
    .time-content-top {
      color: #000;
    }
    .check-item-row {
      display: flex;
      align-items: center;
      width: 100%;
      .MuiFormControl-marginNormal {
        margin-top: 0px;
        margin-bottom: 8px;
      }
      .check-item-left {
        //flex: 1;
        width: 120px;
      }
      .check-item-right {
        //flex: 2;
        padding-right: 20px;
        width: 150px
      }
    }
  }

  .log-item {
    height: 480px;
    margin-bottom: 10px;
    .log-card {
      height: 100%;
      .log-card-body {
        overflow: scroll;
      }
    }
  }

}
