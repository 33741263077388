// Import Bootstrap and its default variables
@import "~@coreui/coreui/scss/coreui.scss";
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

body {
  .flex-style{
    display: flex;
  }
  .cursor-style{
    cursor: pointer;
  }
  .form-signin {
    max-width: 330px;
    padding: 20px;
    margin: 50px auto;
    background-color: rgb(105, 193, 207);
    border-radius: 3px;
  }

  .header {
    height: 3.5em;
  }

  .container {
    .list-group {
      width: 100%;
      .list-group-item {
        background-color: rgb(105, 193, 207) !important;
        color: #f7f7f7;
        border: unset;
        .nav {
          padding: unset;
        }
      }
    }
  }

  .clock-time {
    width: 100%;
    color: #f7f7f7;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }

  .clock-date {
    width: 100%;
    color: #f7f7f7;
    text-align: center;
    font-size: 1em;
    padding-bottom: 20px;
  }

  .location {
    width: 100%;
    text-align: center;
    color: #f7f7f7;
    padding-bottom: 20px;
  }

  .footer {
    width: 100%;
    color: #B3B3B3;
    text-align: center;
    left: 0em;
    padding-bottom: 5px;
    .corp-name {
      font-size: 0.85em;
    }
    .corp-tel {
      font-size: 0.8em;
    }
  }

  .main{
    margin-bottom: 10px;
  }

  .chart-box {
    background-color: #f7f7f7;
    min-height: calc(100vh - 3.5em);
    padding-bottom: 10px;
    top: 3.5em;
    .complete-ratio {
      margin-top: 10px;
      text-align: center;
      background-color: #ffffff;
      border-radius: 10px;
      height: 300px;
      .topic {
        padding-top: 10px;
        font-size: 1em;
        font-weight: bold;
        padding-bottom: 10px;
      }
    }

    .compare-box {
      margin-top: 10px;
      height: 145px;
      border-radius: 8px;
      color: #f7f7f7;
      text-align: center;
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .compare-box-item{
        margin: 4px 0px;
      }
      .compare-box-item-value{
        font-weight: 600;
        font-size: 1.6em;
      }
      .compare-box-item-unit{
        font-size: 0.8em;
      }
    }

    .warning {
      background-color: #CC6600;
    }

    .normal {
      background-color: #00CC99;
    }
  }

  .circle-area {
    width: 200px;
    text-align: center;
    .topic {
      padding-top: 10px;
      font-size: 1em;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .circle {
      width: 170px;
      //height: 170px;
      //border: solid 1px #3FA9F5;
      border-radius: 50%;
      font-size: 25px;
      color: #000;
      line-height: 30px;
      text-align: center;
      background: #fff;
      .value {
        padding-top: 45px;
        font-size: 1.5em;
        font-weight: bold;
        width: 100%;
        text-align: center;
        span {
          font-size: 0.5em;
          font-weight: normal;
          margin-left: 2px;
        }
      }
      .a-style{
        font-size: 0.7em;
        margin-top: 20px;
      }
      .footer {
        font-size: 0.8em;
        color: #000000;
        .label {
          width: 50%;
          font-size: 0.6em;
          float: left;
        }
        .item{
          display: flex;
          .item-left{
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            .color-item{
              width: 40%;
              height: 1em;
            }
            .item-left-excellent{
              background-color: rgb(102,204,153);
            }
            .item-left-good{
              background-color: rgb(51,153,204);
            }
            .item-left-moderate{
              background-color: rgb(204,153,0);
            }
            .item-left-poor{
              background-color: rgb(204,153,153);
            }
            .item-left-poor2{
              background-color: rgb(204,120,153);
            }
            .item-left-poor3{
              background-color: rgb(204,153,103);
            }
          }
          .item-middle{
            flex: 2.5;
          }
          .item-right{
            flex: 2;
          }
        }
        .label_2 {
          width: 100%;
          font-size: 0.7em;
          float: left;
        }
        .item_2{
          display: flex;
          .item-left{
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            .color-item{
              width: 30%;
              height: 1em;
            }
            .item-left-excellent{
              background-color: rgb(102,204,153);
            }
            .item-left-good{
              background-color: rgb(51,153,204);
            }
            .item-left-moderate{
              background-color: rgb(204,153,0);
            }
            .item-left-poor{
              background-color: rgb(204,153,153);
            }
            .item-left-poor2{
              background-color: rgb(204,120,153);
            }
            .item-left-poor3{
              background-color: rgb(154,153,123);
            }
          }
          .item-middle{
            flex: 2.5;
            white-space:nowrap; text-overflow:ellipsis; -o-text-overflow:ellipsis; overflow:hidden;
          }
          //.item-middle:hover{
          //  overflow: visible;
          //}
          .item-right{
            flex: 1;
          }
        }
        .label-left {
          text-align: left;
        }
        .label-right {
          text-align: right;
        }
      }

      .label-list {
        margin: 5px 0 40px 0;
      }
    }
  }

  .air-panel{
    margin-top: 10px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    height: 160px;
    .air-item{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width:20%;
      background-color: #ffffff;
      border-radius: 10px;
      border:1px solid rgb(63,169,245);
      .air-item-item{
        margin: 4px;
      }
      .air-item-value{
        font-size: 2em;
        font-weight: 650;
      }
      .air-item-attr{
        font-size: 0.9em;
        font-weight: 550;
      }
      .air-item-unit{
        font-size: 0.8em;
      }
      .temp-item{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .temp-item-item{
          flex: 1;
          margin: 3px;
        }
        .temp-item-value{
          font-size: 1.6em;
          font-weight: 650;
        }
      }
    }
  }

  .log-panel{
    padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    height: 160px;
    .log-top{
      font-size: 1em;
      .log-top-left{
        float: left;
        font-weight: 600;
      }
      .log-top-right{
        float:right;
        color: rgb(179,179,179);
      }
    }
    .log-detail{
      clear: both;
      height: 90%;
      text-align: left;
      overflow: scroll;
      .log-detail-item{
        clear: both;
        font-size: 0.8em;
        padding:10px 0;
        font-weight: 550;
         .log-detail-item-left{
           float: left;
         }
        .log-detail-item-right{
          float: right;
        }
      }
    }
  }

  .no-scroll{
    overflow: auto;
  }

  .log-detail{
    clear: both;
    //height: 100%;
    text-align: left;
    .log-detail-item{
      clear: both;
      font-size: 0.9em;
      padding:10px 0;
      font-weight: 500;
      .log-detail-item-left{
        float: left;
      }
      .log-detail-item-right{
        float: right;
      }
    }
  }

  .chart-panel{
    background-color: white;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    flex: 1;
    height: 300px;
    border-radius: 10px;
  }

  .alarm-panel{
    height: 300px;
  }

  .adjust-row{
    display: flex;
    .adjust-one{
      flex: 1;
      width: 350px;
      margin: 0 25px 10px 0;
    }
  }
}



