.user-dashboard-panel{
  height: calc(100vh);
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  .user-sider{
    flex: 0 1 200px;
    height: 100%;
    background-color: #2f353a;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5em;
    .sider-1{
      font-size: 2em;
      .MuiTypography-colorTextSecondary {
        color: #bfbfbf;
        font-size: 0.35em;
      }
      .MuiCardHeader-title{
        font-size: 0.6em;
      }
    }
    .sider-2{

    }
    .sider-3{
      background-color: #505a63;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .sider-3-temp{
        font-size: 2.5em;
      }
      .sider-3-title{
        font-size: 1em;
        padding: 10px;
      }
      .sider-3-value{
        font-size: 0.8em;
        padding: 10px;
        .sider-3-value-value{
          font-size: 2em;
        }
      }
    }
    .sider-4{
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 0.6em;
      align-items: center;

      display: flex;
      flex-direction: column;
      align-items: center;
      .sider-2-time{
        font-size: 2em;
      }
      .sider-2-city{
        font-size: 1em;
      }

      .sider-corpname {
        margin-top: 1em;
      }
    }

  }
  .user-content{
    flex: 1;
    height: 100%;
    padding: 10px 20px;
    background-color: #f7f7f7;
  }
}

.user-dashboard-show-panel{
  height: 100%;
  overflow: scroll;
  .show-box-panel {
    background-color: #f7f7f7;
    padding-bottom: 10px;
    .complete-ratio {
      margin-top: 10px;
      text-align: center;
      background-color: #ffffff;
      border-radius: 10px;
      height: 300px;
      .topic {
        padding-top: 10px;
        font-size: 1em;
        font-weight: bold;
        padding-bottom: 10px;
      }
    }

    .compare-box {
      margin-top: 10px;
      height: 145px;
      border-radius: 8px;
      color: #f7f7f7;
      text-align: center;
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .compare-box-item{
        margin: 4px 0px;
      }
      .compare-box-item-value{
        font-weight: 600;
        font-size: 1.6em;
      }
      .compare-box-item-unit{
        font-size: 0.8em;
      }
    }

    .warning {
      background-color: #CC6600;
    }

    .normal {
      background-color: #00CC99;
    }
  }
  .show-item-panel{
    margin: 10px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
  }
  .show-table-panel{
    background-color: white;
    height: 330px;
    overflow: scroll;
  }
}

.show-item-index{
  width: 140px;
  height: 140px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.user-dashboard-show-panel .show-box-panel{
  background-color: white;
  padding: 20px 10px 0 10px;
  .user-chart-panel{
    height: 320px;
    overflow: scroll;
  }

}


.user-detail-circle-panel{
  width: 33.33%;
  float: left;
  margin-bottom: 20px;
  .detail-circle{
    clear: both;
    width: 120px;
    height: 120px;
    border: solid 1px #3FA9F5;
    border-radius: 50%;
    font-size: 1.6em;
    color: #000;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .circle-top{
      height: 50%;
      display: flex;
      align-items: flex-end;
    }
    .circle-bottom{
      font-size: 0.8em;
      height: 50%;
    }
    .control-bottom{
      font-size: 0.6em;
      color: rgb(179,179,179);
    }
    .select{
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}


